import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useState, useEffect, useRef } from 'react';
import { useGlobalState } from '../../context/globalState';
import { useUiState } from '../../context/uiState';
import { useReportState } from '../../context/reportState';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { Report, Table } from '../../types/Common.interfaces';
import { AudienceFilter, Sort } from '../../types/Cube.interfaces';
import { Button, IconButton, Typography, Box, Stack, Skeleton } from '@mui/material';
import { DataGridPro, GridColDef, GridRowModel, GridSortModel, GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { format, parseISO } from 'date-fns';
import databaseSvg from '../../public/images/database-icon.svg';
import axios, { AxiosResponse } from 'axios';
import { ResultSet, DateRange } from '@cubejs-client/core';
import EmptyData from '../../components/emptyData/emptyData';
import TableCheckboxFilter from '../../components/tableCheckboxFilter/tableCheckboxFilter';
import DeleteSegmentModal from '../../components/deleteSegmentModal/deleteSegmentModal';
import useCubejsApiWrapper from '../../apiHelpers/cubejsWrapper';

interface Props {
  title: string;
  hideFooter: boolean;
  seeAllButton?: boolean;
  autoHeight: boolean;
  skeletonRows?: number;
}

const ReportsTable: NextPage<Props> = ({ title, hideFooter, seeAllButton, autoHeight, skeletonRows }) => {
  const {
    apiHeaders, activeDataset, organizationData, organization, loadingOrganizationData, getPageSize, tables, captureEventInPostHog
  } = useGlobalState();
  const { toolbarHeight, displayToast } = useUiState();
  const { statuses, buildDateRange, modifyFilters, buildReportFilter } = useReportState();
  const { modifyJobTitleFilter } = useBasicFiltersState();
  const [reports, setReports] = useState<Report[]>([]);
  const [loadingReports, setLoadingReports] = useState<boolean>(false);
  const [totalNumberOfReports, setTotalNumberOfReports] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [sortData, setSortData] = useState<Sort>({ column: 'createdAt', value: 'desc' });
  const [loadingReportsError, setLoadingReportsError] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [reportToDelete, setReportToDelete] = useState<Report | null>(null);
  const [loadingReportsCount, setLoadingReportsCount] = useState<boolean>(false);
  const [controller] = useState<AbortController>(new AbortController());
  const buttonRef = useRef<any>();
  const iconRef = useRef<any>();
  const apiRef = useGridApiRef<GridApiPro>();
  const router = useRouter();
  const cubejsApiWrapper = useCubejsApiWrapper();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);

  useEffect(() => {
    if (organization) {
      setReports([]);
    }
  }, [organization]);

  useEffect(() => {
    if (apiHeaders && organizationData && activeDataset) {
      const { query } = router;
      if (query.page && query['page-size']) {
        const pageSize = getPageSize(query['page-size'] as string, 10);
        loadReports(pageSize, +query.page, sortData.column, sortData.value);
      } else {
        loadReports(5, 1, sortData.column, sortData.value);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiHeaders, organizationData, activeDataset, router, selectedOptions]);

  const loadReports = async (size: number, page: number, orderBy: string, direction: string) => {
    if (apiHeaders && organizationData && activeDataset) {
      try {
        setLoadingReportsError(false);
        setLoadingReports(true);
        setReports([]);
        setPageSize(size);
        setPage(page);
        const filterParams = selectedOptions.length > 0 ? `&filters=${selectedOptions.join(',')}` : '';
        const queryParams = `?count=${size ? size : 100}&page=${page ? page : 1}${filterParams}&orderBy=${orderBy}&direction=${direction}`;
        const response: AxiosResponse<{ reports: Report[], totalCount: number }> = await axios.get(
          `/api/clients/${organizationData.id}/datasets/${activeDataset.id}/reports${queryParams}`,
          { ...apiHeaders, signal: controller.signal }
        );
        setReports(response.data.reports);
        setTotalNumberOfReports(response.data.totalCount);
        updateReportsCount(response.data.reports);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setReports([]);
          setLoadingReportsError(true);
          setTotalNumberOfReports(0);
        }
      } finally {
        setLoadingReports(false);
      }
    }
  };

  const updateReportsCount = async (reports: Report[]) => {
    try {
      setLoadingReportsCount(true);
      const promises = reports.map(async (report: Report) => {
        const modifiedReport = await getReportSize(report);
        return modifiedReport;
      });
      const newReports = await Promise.all(promises);
      setReports(newReports);
    } catch (error) {
      if (!axios.isCancel(error)) {}
    } finally {
      setLoadingReportsCount(false);
    }
  };

  const getReportSize = async (report: Report) => {
    try {
      const addedStatuses = report.status.split(',');
      const includesRemoved = addedStatuses.find((value: string) => value === 'removed');
      const removedCube = includesRemoved ? '_REMOVED' : '';
      const tableName = report.table.name;
      const cubeName = `${tableName.toUpperCase()}${removedCube}_REPORT`;
      const fromDate = report.dateFrom ? new Date(report.dateFrom) : null;
      const toDate = report.dateTo ? new Date(report.dateTo) : null;
      const dateRangeValues = buildDateRange(report.customDateRange, fromDate, toDate);
      const filters = report.segment ? report.segment.filters : [];
      const modifiedFilters: any[] = [
        ...modifyJobTitleFilter(filters) as AudienceFilter[],
        {
          or: buildReportFilter(tableName, addedStatuses)
        },
      ];
      const resultSet: ResultSet<any> = await cubejsApiWrapper({
        measures: [`${cubeName}.countDistinct`],
        filters: modifyFilters(modifiedFilters as AudienceFilter[], removedCube),
        timeDimensions: [
          {
            dimension: `${cubeName}.${includesRemoved ? '_effective_to' : '_effective_from'}`,
            dateRange: dateRangeValues as DateRange,
          },
        ],
        segments: [`${cubeName}.last_modification`]
      }, { mutexKey: 'new-report-key-total' });
      const totalCount = +resultSet.tablePivot()[0][`${cubeName}.countDistinct`];
      return { ...report, totalCount };
    } catch (error) {
      return { ...report, totalCount: 0 };
    }
  };

  const handleNewButtonClick = () => {
    router.push('/reports/new');
  };

  const handleChangePageSize = (value: number) => {
    router.push(`/reports?page=1&page-size=${value}`);
  };

  const handlePageChanged = (value: number) => {
    router.push(`/reports?page=${value}&page-size=${pageSize}`);
  };

  const handleSortChange = async (model: GridSortModel) => {
    if (model.length && model[0].sort) {
      setSortData({ column: model[0].field, value: model[0].sort });
      loadReports(pageSize, page, model[0].field, model[0].sort);
    } else {
      setSortData({ column: 'createdAt', value: 'desc' });
      loadReports(pageSize, page, 'createdAt', 'desc');
    }
  };

  const handleCloseFilter = (event:  MouseEvent | TouchEvent | React.MouseEvent<HTMLElement>) => {
    if (event.target !== buttonRef.current && event.target !== iconRef.current) {
      setFilterAnchorEl(null);
    }
  };

  const handleToggleFilter = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(filterAnchorEl ? null : event.currentTarget);
  };

  const handleFilterChange = (value: string[]) => {
    setSelectedOptions(value);
  };

  const deleteReport = (report: Report) => {
    setOpenDeleteModal(true);
    setReportToDelete(report);
  };

  const handleDeleteReport = async () => {
    if (organizationData && activeDataset && apiHeaders && reportToDelete) {
      try {
        setOpenDeleteModal(false);
        // eslint-disable-next-line no-unused-vars
        const response: AxiosResponse<{ message: string }> = await axios.patch(
          `/api/clients/${organizationData.id}/datasets/${activeDataset.id}/reports/${reportToDelete.id}`,
          JSON.stringify({ data: { isDeleted: true } }),
          { ...apiHeaders, signal: controller.signal }
        );
        displayToast('Your report has been deleted!', 'success');
        captureEventInPostHog('Delete report', activeDataset.productName);
        loadReports(10, 1, sortData.column, sortData.value);
      } catch (error) {
        if (!axios.isCancel(error)) {
          displayToast('There was an error while deleting report.', 'error');
        }
      }
    }
  };

  const findEntity = (id: string): string => {
    const findTable = tables.find((table: Table) => table.id === id);
    return findTable ? findTable.displayName : '';
  };

  const findStatus = (statusValue: string) => {
    const findStatus = statuses.find((item: { value: string, label: string, tooltip: string }) => item.value === statusValue);
    return findStatus ? findStatus.label : statusValue;
  };

  const columns: GridColDef[] = [
    {
      field: 'reportName',
      headerName: 'Reports Name',
      flex: 1,
      minWidth: 190,
      cellClassName: 'text-highlight',
      renderCell: (params: GridRenderCellParams) => (
        <Box
          sx={{ cursor: 'pointer'  }}
          onClick={() => router.push(`/reports/${params.row.id}`)}
        >
          {params.value}
        </Box>
      ),
      pinnable: false,
    },
    {
      field: 'segment',
      headerName: 'Data Source',
      width: 190,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.segment ? params.row.segment.displayName : 'Full Database'}
        </>
      ),
    },
    {
      field: 'table',
      headerName: 'Entity',
      width: 190,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {findEntity(params.row.tableId)}
        </>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 190,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.value.split(',').map((value: string) => findStatus(value)).join(', ')}
        </>
      ),
    },
    {
      field: 'totalCount',
      headerName: 'Total Records',
      width: 190,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {loadingReportsCount ? (
            <Skeleton width='100%' height={40} sx={{ bgcolor: '#F5EEFA', zIndex: '1' }} />
          ) : params.value ? params.value.toLocaleString() : 0}
        </>
      ),
    },
    {
      field: 'customDateRange',
      headerName: 'Date Range',
      width: 190,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.value !== 'Custom' ? (
            params.value
          ) : (
            <>
              {params.row.dateFrom ? format(parseISO(params.row.dateFrom), 'MM/dd/yyyy') : ''}
              {' '}
              -
              {' '}
              {params.row.dateTo ? format(parseISO(params.row.dateTo), 'MM/dd/yyyy') : ''}
            </>
          )}
        </>
      ),
    },
    {
      field: 'author',
      headerName: 'Created by',
      width: 190,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.author.firstName} {params.row.author.lastName}
        </>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created date',
      width: 140,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <>
          {format(parseISO(params.value), 'MM/dd/yyyy')}
        </>
      ),
      pinnable: false,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: 120,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <Box className='actions-wrapper icons'>
          <IconButton
            onClick={() => {
              router.push(`/reports/${params.row.id}`);
            }}
          >
            <EditOutlinedIcon className='table-body-text-color' />
          </IconButton>
          <IconButton
            onClick={() => deleteReport(params.row)}
          >
            <DeleteOutlinedIcon className='table-body-text-color' />
          </IconButton>
        </Box>
      ),
      headerClassName: 'table-header hide-right-separator',
      resizable: false,
    }
  ];

  return (
    <Box
      className='u-flex u-flex-direction-column'
      sx={{
        minHeight: !autoHeight ? `calc(100vh - ${toolbarHeight}px - 63px)` : '345px',
        height: !autoHeight ? '630px' : '345px',
      }}
    >
      <Box className='table-top-wrapper u-flex u-flex-space-between u-flex-align-center u-p-16'>
        <Typography className='table-top-content fs-title-medium'>{title}</Typography>
        <Box>
          {seeAllButton ? (
            <Button
              className='u-mr-16 u-text-transform-none'
              variant='text'
              onClick={()=> router.push('/reports?page=1&page-size=10')}
            >
              See All
            </Button>
          ) : null}
          <Button
            startIcon={<AddOutlinedIcon />}
            className='details-button u-mr-16'
            onClick={handleNewButtonClick}
          >
            Create New Report
          </Button>
          <IconButton
            ref={buttonRef}
            onClick={(event: React.MouseEvent<HTMLElement>) => handleToggleFilter(event)}
          >
            <TuneOutlinedIcon
              className='table-top-content-icon'
              ref={iconRef}
            />
          </IconButton>
        </Box>
      </Box>
      <DataGridPro
        columns={columns}
        rows={reports}
        page={page - 1}
        loading={loadingReports || loadingOrganizationData}
        pageSize={pageSize}
        rowCount={totalNumberOfReports}
        sx={{
          borderRadius: '0',
          backgroundColor: '#fff',
        }}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        autoHeight={autoHeight}
        headerHeight={48}
        rowHeight={48}
        paginationMode='server'
        sortingMode='server'
        sortingOrder={['asc', 'desc']}
        className='table'
        hideFooter={hideFooter}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        getRowId={(row: GridRowModel) => row.id}
        onSortModelChange={(model: GridSortModel) => handleSortChange(model)}
        apiRef={apiRef}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        error={loadingReportsError === true || undefined}
        onPageChange={(page) => handlePageChanged(page+1)}
        onPageSizeChange={(newPageSize) => handleChangePageSize(newPageSize)}
        components={{
          NoRowsOverlay: () => <EmptyData text='Create a report and it will appear here.' />,
          NoResultsOverlay: () => <EmptyData text='Create a report and it will appear here.' />,
          LoadingOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              {Array.from(Array(skeletonRows || 5).keys()).map((value: number) => (
                <Skeleton
                  key={value + 1}
                  variant='rectangular'
                  animation='wave'
                  width='100%'
                  height={36}
                  className='u-my-6'
                  sx={{ bgcolor: '#F5EEFA', zIndex: '1' }}
                />
              ))}
            </Stack>
          ),
          ErrorOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              <Box
                className='u-flex u-flex-justify-center u-flex-align-center u-w-64-px u-h-64-px u-br-50'
                sx={{ background: '#D9D9D9', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
              >
                <Image
                  src={databaseSvg}
                  alt='Database icon'
                />
              </Box>
              <Typography className='fs-title-large u-fw-700'>Loading Error</Typography>
              <Typography className='fs-body-regular'>Oops, sorry, we seem to be having an issue on our end.</Typography>
            </Stack>
          )
        }}
      />
      <TableCheckboxFilter
        anchorEl={filterAnchorEl}
        handleClose={handleCloseFilter}
        handleFilterChange={handleFilterChange}
        title='Created By'
        selectedOptions={selectedOptions}
        filterEntity='reports'
      />
      <DeleteSegmentModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handleAccept={handleDeleteReport}
        segmentName={reportToDelete?.reportName || ''}
        setReportToDelete={setReportToDelete}
        type='report'
      />
    </Box>
  );
};

export default ReportsTable;


